<template>

    <main-container icon="mdi-alert" title="Não autorizado">
        <v-container
        class="text-center"
        fill-height
      >
        <v-row align="center">
          <v-col>
            <h1 class="text-h1 primary--text">Ops, 403</h1>

            <p class="text-h5 mt-4">Você não possui autorização para acessar o conteúdo!</p>
            <p class="body-1">Entre em contato com o administrador responsável pela sua instituição, para verificação das suas permissões!</p>

            <v-btn
              :to="`/`"
              color="primary"
              outlined
            >
              Voltar para o início!
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </main-container>
</template>

<script>
export default {
    name: 'NaoAutorizado'
}
</script>